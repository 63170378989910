import React, { useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import { Form } from '@fluentui/react-northstar';
import { Input } from '@fluentui/react-northstar';
import { Text } from '@fluentui/react-northstar';

import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';
import { CocoomChannelSelector } from '../containers/CocoomChannelSelector';
import { AlertMessages } from '../containers/AlertMessages';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  registerConfigurableTabCreator
} from '../reducers/cocoom';

import { createEntityId } from '../utils/createEntityId';

import { AUTHENTIFICATION_USECASE } from '../propTypes';

export function ConfigurableTabCreate() {
  const dispatch = useDispatch();
  const [tr] = useTranslation();
  const cocoomState = useSelector(state => state.cocoom);
  const authState = useSelector(state => state.auth);
  const [tabSettings, setTabSettings] = useState({});
  const [cocoomChannel, setCocoomChannel] = useState(null);


  React.useEffect(() => {
    if (cocoomState.msteamsContext && authState.isUserAuthentified) {
      const validityState = tabSettings.suggestedDisplayName && tabSettings.suggestedDisplayName.length > 0;
      microsoftTeams.pages.config.setValidityState(validityState);

      if (validityState) {
        dispatch(registerConfigurableTabCreator(microsoftTeams, cocoomState.msteamsContext, tabSettings, cocoomChannel.account.id, cocoomChannel.ref));
      }
    }
  }, [cocoomChannel, dispatch, cocoomState.msteamsContext, authState.isUserAuthentified, tabSettings, tabSettings.suggestedDisplayName]);

  function handleTabNameChange(e, component) {
    const newTabSettings = {...tabSettings, suggestedDisplayName: component.value};
    setTabSettings(newTabSettings);
  }

  function handleChannelSelection(cocoomChannel) {
    setCocoomChannel(cocoomChannel);
    if (!cocoomChannel) {
      setTabSettings({});
      return;
    }

    const channelEmbedUrl = new URL(cocoomChannel.embedUrl);
    const channelUrlDomain = channelEmbedUrl.hostname;

    const embedUrl = encodeURI('https://' + window.location.host + '/tab/display?theme={theme}&locale={locale}&displayType=channel&displayRef=' + cocoomChannel.ref + '&displayDomain=' + channelUrlDomain + '&fullHeight=true');

    setTabSettings({
      removeUrl: 'https://' + window.location.host + '/tab/delete?theme={theme}&locale={locale}',
      websiteUrl: cocoomChannel.url,
      contentUrl: embedUrl,
      entityId: 'channel-' + createEntityId(cocoomChannel.account.id, cocoomChannel.ref),
      suggestedDisplayName: cocoomChannel.name
    });
  }

  const uiState = {};
  if (cocoomChannel) {
    uiState.tabNameInput = true;
  }

  return (
    <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_CONFIG}>
      <Form>
        <CocoomChannelSelector onChannelSelection={handleChannelSelection}/>
        {uiState.tabNameInput && (
          <div>
            <Text as="div">{tr('general.tabName')}</Text>
            <Input value={tabSettings.suggestedDisplayName} onChange={handleTabNameChange}/>
          </div>
        )}
        <AlertMessages action={cocoomState.action} actionState={cocoomState.actionState} actionError={cocoomState.actionError}/>
      </Form>
    </AuthenticationProtectedContent>
  );
}
