import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import { useSelector, useDispatch} from 'react-redux';

import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';
import { AlertMessages } from '../containers/AlertMessages';
import { CocoomChannelSelector } from '../containers/CocoomChannelSelector';

import {
  registerConnectorCreator
} from '../reducers/cocoom';

import { createEntityId } from '../utils/createEntityId';

import { AUTHENTIFICATION_USECASE } from '../propTypes';

export function CreateConnector() {
  const cocoomState = useSelector(state => state.cocoom);
  const dispatch = useDispatch();

  function handleChannelSelection(cocoomChannel) {
    microsoftTeams.pages.config.setValidityState(!!cocoomChannel);
    if (cocoomChannel) {
      const connectorSettings = {
        contentUrl:  'https://' + window.location.host + '/connector/configure?theme={theme}&locale={locale}',
        entityId: 'connector-' + createEntityId(cocoomChannel.account.id, cocoomChannel.ref),
        configName: `Cocoom_${cocoomChannel.account.name}_${cocoomChannel.name}`
      }


      dispatch(registerConnectorCreator(microsoftTeams, cocoomState.msteamsContext, connectorSettings, cocoomChannel.account.id, cocoomChannel.ref));
    }
  }

  return (
    <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.CONNECTOR_CONFIG}>
      <CocoomChannelSelector onChannelSelection={handleChannelSelection}/>
      <AlertMessages actionError={cocoomState.actionError}/>
    </AuthenticationProtectedContent>
  );
}
