import React from 'react';
import ReactDOM from 'react-dom';
import * as microsoftTeams from "@microsoft/teams-js";

import { Provider as FUIProvider } from '@fluentui/react-northstar';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

import i18n from './i18n';
import {getMicrosoftTeamsTheme} from './utils/getMicrosoftTeamsTheme';
import {setFullHeight} from './utils/setFullHeight';

import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";

import { WelcomePage } from './pages/WelcomePage';
import { TabSignIn } from './pages/TabSignIn';
import { ConfigurableTabCreate } from './pages/ConfigurableTabCreate';
import { ConfigurableTabDelete } from './pages/ConfigurableTabDelete';
import { PersonalTab } from './pages/PersonalTab';
import { CreateConnector } from './pages/CreateConnector';
import { HelpFrame } from './pages/HelpFrame';
import { ConfigurableTab } from './pages/ConfigurableTab';

import {
  setMsTeamsContext,
  setMsTeamsTheme
} from './reducers/cocoom';

import {
  setConfiguration
} from './reducers/configuration';


function init() {
  // FullHeight parameter
  const query = new URLSearchParams(document.location.search);
  if (query.get('fullHeight')) {
    setFullHeight();
  }
  // Get Teams theme
  const msteamsTheme = query.get('theme');
  const store = createStore(reducer, applyMiddleware(thunk));
  try {
    if (window && document && document.querySelector('meta[property=cfg]')) {
      try {
        store.dispatch(setConfiguration(JSON.parse(decodeURIComponent(escape(atob(document.querySelector('meta[property=cfg]').getAttribute('content')))))));
      } catch (decodingError) {
        console.error('Configuration decoding error');
      }
    }

    store.dispatch(setMsTeamsTheme(msteamsTheme));
  } catch(error) {
    ReactDOM.render(<div>
      <h1>Configuration Error</h1>
      <p>{error.message}</p>
      <p>Please, contact us at support@cocoom.com</p>
    </div>, document.getElementById('root'));
    return;
  }

  initTeams().then((context) => {
    if (context) {
      i18n.changeLanguage(context.locale);
      store.dispatch(setMsTeamsContext(context));
    }
  }).catch((error) => {
    console.error('Teams context initialization error:', error);
  });

  microsoftTeams.app.registerOnThemeChangeHandler(function(theme) {
    store.dispatch(setMsTeamsTheme(theme));
  });

  async function initTeams() {
    try {
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      return context;
    } catch (error) {
      console.error('Erreur initializing MS Teams context:', error);
      return null;
    }
  }

  function NoMatch() {
    let location = useLocation();
    return (
      <div>
        <h1>Page not found</h1>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }

  function TabRoutes({match}) {
    const cocoomState = useSelector(state => state.cocoom);
    return (
      <FUIProvider theme={getMicrosoftTeamsTheme(msteamsTheme || cocoomState.msteamsTheme)} styles={{backgroundColor: 'transparent'}}>
        <Route path={`${match.path}/configure`}><ConfigurableTabCreate /></Route>
        <Route path={`${match.path}/delete`}><ConfigurableTabDelete /></Route>
      </FUIProvider>
    );
  }

  function ConnectorRoutes({match}) {
    const cocoomState = useSelector(state => state.cocoom);
    return (
      <FUIProvider theme={getMicrosoftTeamsTheme(msteamsTheme || cocoomState.msteamsTheme)} styles={{backgroundColor: 'transparent'}}>
        <Route path={`${match.path}/configure`}><CreateConnector /></Route>
        <Route path={`${match.path}/content`}><h1>CONTENT CONNECTOR</h1></Route>
        <Route path={`${match.path}/delete`}><h1>DELETE CONNECTOR</h1></Route>
      </FUIProvider>
    );
  }


  ReactDOM.render(
    <Provider store={store} theme={getMicrosoftTeamsTheme(msteamsTheme)}>
        <Router>
          <Switch>
            <Route path="/" exact><WelcomePage /></Route>
            <Route path="/tab/signin"><TabSignIn /></Route>

            <Route path="/tab/display"><ConfigurableTab /></Route>
            <Route path="/tab" component={TabRoutes}/>

            <Route path="/personal/knowledgebase"><PersonalTab iframeId="knowledge-base" embedPath="/msteams/knowledgebase"/></Route>
            <Route path="/personal/dashboard"><PersonalTab iframeId="dashboard" embedPath="/msteams/dashboard"/></Route>
            <Route path="/personal/help"><HelpFrame locale={query.get('locale')} /></Route>

            <Route path="/connector" component={ConnectorRoutes}/>

            <Route path="*"><NoMatch /></Route>
          </Switch>
        </Router>
    </Provider>, document.getElementById('root')
  );
}

if (process.env.NODE_ENV === "development") {
    console.log('[ MirageJS ] Launching API mocking server...');
    import('./mocking/apiMockingServer').then((m) => {
      m.makeApiMockingServer();
      console.log('[ MirageJS ] Launched.');
      const metaForConfiguration = document.querySelector('meta[property=cfg]');
      metaForConfiguration.setAttribute('content', btoa(unescape(encodeURIComponent(JSON.stringify(require('./mocking/config'))))));
      init();
    });
} else {
  init();
}
