import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';
import { Alert } from '@fluentui/react-northstar';

import {
  registerConfigurableTabDestructor
} from '../reducers/cocoom';

import { AUTHENTIFICATION_USECASE } from '../propTypes';

export function ConfigurableTabDelete() {
  const [tr] = useTranslation();
  const cocoomState = useSelector(state => state.cocoom);
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (cocoomState.msteamsContext && authState.isUserAuthentified) {
      microsoftTeams.pages.config.setValidityState(authState.isUserAuthentified === true);

      dispatch(registerConfigurableTabDestructor(microsoftTeams, cocoomState.msteamsContext));
    }
  }, [dispatch, cocoomState.msteamsContext, authState.isUserAuthentified]);

  return (
    <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_DELETION}>
      <Alert
        content={tr('alerts.deleteInformation')}
        style={{paddingTop: 10, paddingBottom: 10}}
      />
    </AuthenticationProtectedContent>
  );
}
