import React from 'react';
import {
  AUTHENTIFICATION_USECASE,
  authentificationUsecaseType
} from '../propTypes';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as microsoftTeams from "@microsoft/teams-js";
import {
  Button,
  Loader,
  Text
} from '@fluentui/react-northstar';

import { CallToSignIn } from '../components/CallToSignIn';
import { CallToSignUp } from '../components/CallToSignUp';

import styling from '../components/styling';

import {
  CHECK_AUTHENTIFICATION_ACTION_ID,
  checkAuthentification,
  GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID,
  getAssociatedCocoomAccounts,
  SIGNOUT_ACTION_ID,
  signOut
} from '../reducers/auth'
import { AlertMessages } from '../containers/AlertMessages';


const REDIRECTION_TO_PAGE_PARAM = 'next';

const propTypes = {
  usecase: authentificationUsecaseType,
  children: undefined
}

export function AuthenticationProtectedContent({usecase, children}) {
  const [tr] = useTranslation();
  const authState = useSelector(state => state.auth);
  const cocoomState = useSelector(state => state.cocoom);
  const configurationState = useSelector(state => state.configuration);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (authState.isUserAuthentified === null) {
      dispatch(checkAuthentification());
    }

    if (authState.isUserAuthentified === false && cocoomState.msteamsContext.user && cocoomState.msteamsContext.user.tenant) {
      dispatch(getAssociatedCocoomAccounts({azureTenantId: cocoomState.msteamsContext.user.tenant.id, azureUserEmail: cocoomState.msteamsContext.user.loginHint}));
    }
  }, [dispatch, authState.isUserAuthentified, cocoomState.msteamsContext]);


  function redirectToRequestedPage() {
    if (window.location.search && window.location.search.indexOf(REDIRECTION_TO_PAGE_PARAM) > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const requestedPage = urlParams.get(REDIRECTION_TO_PAGE_PARAM);
      window.location.replace(requestedPage);
    }
  }


  function handleAuthenticationCallback() {
    if (usecase === AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB) {
      redirectToRequestedPage();
      return;
    }

    dispatch(checkAuthentification());
  }


  function getEncodedMsTeamsContext() {
    const data = {
      tid: cocoomState.msteamsContext.user.tenant.id,
      uid: cocoomState.msteamsContext.user.id,
      gid: cocoomState.msteamsContext.team ? cocoomState.msteamsContext.team.groupId : undefined,
      l: cocoomState.msteamsContext.user.loginHint,
      lg: cocoomState.msteamsContext.app.locale.substring(0, 2)
    };
    const encodedMSTeamsContext = btoa(unescape(encodeURIComponent(JSON.stringify(data))));

    return encodedMSTeamsContext;
  }

  function authenticateInMSTeams(url, width = 450, height = 650, isExternal = false) {
    return microsoftTeams.authentication.authenticate({
      url,
      width,
      height,
      isExternal,
    }).then(() => {
      handleAuthenticationCallback();
    }).catch((error) => {
      console.error('Error of authentication in MS Teams:', error);
      throw error;
    });
  }

  function handleAssociateCocoomAccount() {
    const url = configurationState.associateToTeamsPageUrl + `?d=${getEncodedMsTeamsContext()}`;
    console.log('handleAssociateCocoomAccount using url: ', url);

    authenticateInMSTeams(url).catch((error) => {
      console.error('Error of authentication while trying to associate a Cocoom account to a MS Teams account:', error);
      handleAuthenticationCallback();
    });
  }

  function handleConnectToCocoom(forceEnterCredentials = false) {
    let fromSingleAccountQueryPart = '';
    if (authState.associatedCocoomAccounts && authState.associatedCocoomAccounts.length === 1) {
      fromSingleAccountQueryPart = `from=${authState.associatedCocoomAccount.url}&next=${authState.associatedCocoomAccount.url}`;
    }

    let forceEnterCredentialsQueryPart = '';
    if (forceEnterCredentials) {
      forceEnterCredentialsQueryPart = `&prompt=true`;
    }

    if (fromSingleAccountQueryPart) {
      fromSingleAccountQueryPart += '&';
    }

    const url = configurationState.signInPageUrl + `?${fromSingleAccountQueryPart}d=${getEncodedMsTeamsContext()}${forceEnterCredentialsQueryPart}`;
    authenticateInMSTeams(url).catch((error) => {
      console.error('Error of authentication while trying to connect to Cocoom account from MS Teams:', error);
      handleAuthenticationCallback();
    });
  }


  function handleCreateCocoomAccount() {
    let campaignLanguage = cocoomState.msteamsContext.app.locale.substring(0, 2);
    if (campaignLanguage !== 'fr') {
      campaignLanguage = 'en';
    }

    const url = configurationState.signUpPageUrl + `?d=${getEncodedMsTeamsContext()}&utm_source=teams&utm_medium=app&utm_campaign=${campaignLanguage}`;
    authenticateInMSTeams(url, 1024, 800).catch((error) => {
      console.error('Error of authentication while trying to create a new Cocoom account from MS Teams:', error);
      handleAuthenticationCallback();
    });
  }


  const uiState = {};
  if (usecase !== AUTHENTIFICATION_USECASE.SILENTLY_HIDE) {
    if ((authState.action === CHECK_AUTHENTIFICATION_ACTION_ID || authState.action === GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID) && authState.actionState === 'working') {
      uiState.showConnectionLoader = true;
    }

    if (authState.action === SIGNOUT_ACTION_ID && authState.actionState === 'working') {
      uiState.showSignoutLoader = true;
    }

    if (authState.isUserAuthentified === false && authState.associatedCocoomAccount !== undefined && authState.actionState !== 'working') {
      if (authState.associatedCocoomAccount) {
        uiState.showCallToSignIn = true;
      } else {
        uiState.showCallToSignUp = true;
      }
    } else if (authState.isUserAuthentified && authState.actionState !== 'working' && cocoomState.actionState !== 'working') {
      uiState.showSignoutButton = true;
    }
  }

  if (authState.isUserAuthentified) {
    uiState.showChildren = true;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const receivedError = urlParams.get('error');
  if (receivedError && receivedError === 'Unauthorized') {
    uiState.showChildren = false;
    uiState.unauthorizedAccess = true;
  }


  return (
    <React.Fragment>
      {uiState.showConnectionLoader &&
        <div style={{position: 'absolute', left: 'calc(50% - 20px)', top: 'calc(50% - 20px)'}}>
          <Loader />
        </div>
      }

      {uiState.showCallToSignIn &&
        <CallToSignIn
          theme={cocoomState.msteamsTheme}
          usecase={usecase}
          onConnectToCocoom={handleConnectToCocoom}
        />
      }

      {uiState.showCallToSignUp &&
        <CallToSignUp
          theme={cocoomState.msteamsTheme}
          usecase={usecase}
          onCreateCocoomAccount={handleCreateCocoomAccount}
          onAssociateCocoomAccount={handleAssociateCocoomAccount}
        />
      }

      {uiState.showSignoutButton &&
        <div style={{ textAlign: 'right', margin: styling.spacing(1) }}>
          {cocoomState.msteamsContext && cocoomState.msteamsContext.user && cocoomState.msteamsContext.user.loginHint &&
            <span style={{ paddingRight: styling.spacing(1) }}>
              <Text size="small" styles={{color: '#aaaaaa'}}>
                {
                  tr('authentication.authenticationEmailUsage',
                    {email: authState.user ? authState.user.email : 'n/a'})
                }
              </Text>
            </span>
          }

          <Button
            size="small"
            content={tr('authentication.signout')}
            loading={uiState.showSignoutLoader}
            disabled={!authState.user}

            onClick={() => dispatch(signOut())}
          />
        </div>
      }

      <React.Fragment>
        {uiState.showChildren && children}
        {!uiState.showChildren && uiState.unauthorizedAccess &&
          <AlertMessages
            actionState='failed'
            actionError={{statusCode: 401}}
          />
        }
      </React.Fragment>

    </React.Fragment>
  );
}

AuthenticationProtectedContent.propTypes = propTypes;
