import React from 'react';
import PropTypes from 'prop-types';
import {
  AUTHENTIFICATION_USECASE,
  authentificationUsecaseType
} from '../propTypes';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Text
} from '@fluentui/react-northstar';

import { Emoji } from './Emoji';
import { PageTitle } from './PageTitle';
import { PageSubtitle } from './PageSubtitle';


import styling from './styling';

const propTypes = {
  onAssociateCocoomAccount: PropTypes.func,
  onCreateCocoomAccount: PropTypes.func,
  usecase: authentificationUsecaseType,
  tid: PropTypes.string.isRequired
}

export function CallToSignUp({usecase, onAssociateCocoomAccount = () => {}, onCreateCocoomAccount = () => {}, tid, theme = 'default'}) {
  const [tr] = useTranslation();

  const uiState = {
    small: usecase === AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_CONFIG || usecase === AUTHENTIFICATION_USECASE.CONNECTOR_CONFIG
  };

  function IconTextBlock({iconUrl, verb, text, tags, width = '100%', size = 'default'}) {
    return (
      <div style={{width: width, padding: styling.spacing(1), display: 'inline-block'}}>
        <Flex gap="gap.small">
          <img src={iconUrl} style={{height: 26}} alt=""/>
          <div>
            <Text size={uiState.small ? 'small' : 'medium'}><strong>{verb}</strong>&nbsp;&nbsp; {text}</Text><br/>
            <Text size="small" styles={{ color: '#aaaaaa' }}>{tags}</Text>
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <Flex styles={{margin: uiState.small ? 0 : styling.spacing([0, 3])}} space="around">
      <div style={{width: '560px', minWidth: '520px', textAlign: 'left', margin: uiState.small ? 2 : styling.spacing([2, 3])}}>
        <PageTitle theme={theme} styles={{textAlign: 'center'}} small={uiState.small} text={tr('callToSignUp.title')} />
        <PageSubtitle theme={theme} styles={{textAlign: 'center'}} small text={tr('callToSignUp.subtitle')} />

        <div style={{textAlign: 'center', paddingBottom: uiState.small ? '1em' : '2em'}}>
          <div style={{display: 'inline-block', width: '40%', padding: '1em'}}>
            <Text weight="semibold" size={uiState.small ? 'small' : 'medium'}>{tr('callToSignUp.noPlatform')}</Text><br/>
            <Text size={uiState.small ? 'medium' : 'large'} style={{display: 'block', margin: '.5em'}}><Emoji symbol="👇"/></Text>
            <Button primary fluid onClick={onCreateCocoomAccount} content={tr('callToSignUp.createPlatform')}/>
          </div>
          <div style={{display: 'inline-block', width: 'calc(20% - 3em)', padding: '1em'}}>
          <Text important as="p" size={uiState.small ? 'medium' : 'large'}>{tr('callToSignUp.orBetweenCreateAndAssociate')}</Text><br/>
          </div>
          <div style={{display: 'inline-block', width: '40%', padding: '1em'}}>
            <Text weight="semibold" size={uiState.small ? 'small' : 'medium'}>{tr('callToSignUp.alreadyHavePlatform')}</Text><br/>
            <Text size={uiState.small ? 'medium' : 'large'} style={{display: 'block', margin: '.5em'}}><Emoji symbol="👇"/></Text>
            <Button primary fluid onClick={onAssociateCocoomAccount} content={tr('callToSignUp.associatePlatform')}/>
          </div>
        </div>

        <IconTextBlock
          iconUrl="/writing-hand.png"
          verb={tr('callToSignUp.publish.verb')}
          text={tr('callToSignUp.publish.text')}
          tags={tr('callToSignUp.publish.keywords')}
          width="50%"
          size={uiState.size}
        />
        <IconTextBlock
          iconUrl="/graduation-cap.png"
          verb={tr('callToSignUp.organize.verb')}
          text={tr('callToSignUp.organize.text')}
          tags={tr('callToSignUp.organize.keywords')}
          width="50%"
          size={uiState.size}
        />
        <IconTextBlock
          iconUrl="/bellhop-bell.png"
          verb={tr('callToSignUp.notify.verb')}
          text={tr('callToSignUp.notify.text')}
          tags={tr('callToSignUp.notify.keywords')}
          width="50%"
          size={uiState.size}
        />
        <IconTextBlock
          iconUrl="/rocket.png"
          verb={tr('callToSignUp.help.verb')}
          text={tr('callToSignUp.help.text')}
          tags={tr('callToSignUp.help.keywords')}
          width="50%"
          size={uiState.size}
        />

        <div style={{textAlign: 'center', marginTop: uiState.small ? '.5em' : '1.5em'}}>
          <Text
            as="a"
            styles={{
              color: (theme === 'default') ? styling.color.tonic.dark : styling.color.tonic.light,
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => window.location.href = 'mailto:support@cocoom.com'}
          >
            support@cocoom.com
          </Text>
        </div>
      </div>
      {!uiState.small && <Flex.Item align='end'>
        <img src="/astronaut.png" style={{width: 300}} alt={tr('callToSignUp.astroAlt')} styles={{margin: uiState.small ? 2 : styling.spacing([2, 3])}}/>
      </Flex.Item>}
    </Flex>
  );
}

CallToSignUp.propTypes = propTypes;
