import { teamsV2Theme, teamsDarkV2Theme, teamsHighContrastTheme } from '@fluentui/react-northstar'


export function getMicrosoftTeamsTheme(theme) {
  let msTeamsTheme = teamsV2Theme;

  switch(theme) {
    case 'dark':
      msTeamsTheme = teamsDarkV2Theme;
      break;
    case 'contrast':
      msTeamsTheme = teamsHighContrastTheme;
      break;
  }

  return msTeamsTheme;
}
