import React from 'react';
import {
  Provider as FUIProvider
 } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';

import { AUTHENTIFICATION_USECASE } from '../propTypes';

import { AlertMessages } from '../containers/AlertMessages';
import {getMicrosoftTeamsTheme} from '../utils/getMicrosoftTeamsTheme';
import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';


export function ConfigurableTab() {
  const cocoomState = useSelector(state => state.cocoom);
  const authState = useSelector(state => state.auth);

  React.useEffect(() => {
    if (authState.isUserAuthentified) {
      const query = new URLSearchParams(document.location.search);
      switch(query.get('displayType')) {
        case 'channel':
          const domain = query.get('displayDomain');
          const ref = query.get('displayRef');
          if (domain && ref) {
            window.location.replace('https://' + domain + '/msteams/channels/' + ref + '?sob=true');
          }
          break;

        default:
          console.log('unsupported displayType');
          break;
      }
    }
  }, [authState.isUserAuthentified]);

  const uiState = {};
  if (cocoomState.actionState === 'working') {
    uiState.loader = true;
  }

  return (
    <FUIProvider theme={getMicrosoftTeamsTheme(cocoomState.msteamsTheme)} style={{background: 'transparent'}}>
      <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.PERSONAL_TAB}>
        <AlertMessages actionError={cocoomState.actionError}/>
      </AuthenticationProtectedContent>
    </FUIProvider>
  );
}
